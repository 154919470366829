import React from 'react'

import { styles } from '@/components/shared'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: '21px 56px 100px 42px',
	},
}))

export const MiddlePaneLayout = ({ children }: { children: React.ReactNode }) => {
	const classes = useStyles()

	return <main className={classes.wrapper}>{children}</main>
}
