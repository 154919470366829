import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys } from 'humps'
import get from 'lodash/get'

import { STORAGE } from '@/constants'
import util from '@/utils'

export const formDataConfig = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	},
}

const instance = axios.create({
	baseURL: util.urlHandler.getBaseUrl(),
	headers: {
		'Content-Type': 'application/json',
	},
})

const IGNORED_UNAUTHORIZED_ROUTES = ['/auth', 'is_logged_in']
const UNAUTHORIZED_STATUS = 401

const handleUnauthorized = (error: object) => {
	const status = get(error, 'response.status')
	const url = get(error, 'request.responseURL', '')

	if (status === UNAUTHORIZED_STATUS && !IGNORED_UNAUTHORIZED_ROUTES.find((r) => url.includes(r))) {
		// window.location.reload();
	}
}

instance.interceptors.response.use(
	(response: AxiosResponse) => {
		if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8') {
			response.data = camelizeKeys(response.data, (key, convert) => {
				// converter remove '-' from string, in some cases we might use uuid as object key and this will cause issues
				// E.g. this  21a0fe90-9bb2-408c-ad30-eab29444f2fe was converted into 21a0fe909bb2408cad30eab29444f2fe
				return key.includes('-') ? key : convert(key)
			})
		}

		return response
	},
	(error) => {
		handleUnauthorized(error)
		return Promise.reject({
			extended: get(error, 'response.data.error.extended', {}),
			message: get(error, 'response.data.error.message', error),
			code: get(error, 'response.data.error.errcode', 0),
		})
	},
)

instance.interceptors.request.use((req: AxiosRequestConfig) => {
	const token = util.storage.get(STORAGE.ACCESS_TOKEN_PATH)
	const userId = util.storage.get(STORAGE.CURRENT_USER_ID_PATH)
	const userAgent = window.navigator.userAgent

	if (token) {
		req.headers = {
			...req.headers,
			accesstoken: token,
			userId: `${userId}`,
			userAgent,
		}
	}
	return req
})

export default instance
