import * as config from '@/constants/configuration'
import http from '@/api/http/requestHttp'

interface SearchOrganizationsParams {
	q?: string
	userId: number
	campusId: number | null
	categoryId: number | null
	status: string | null
	orgTypeId: number | null
	membershipTypeId: number[] | null
}

const searchOrganizations = async ({ q, userId, campusId, categoryId, status, orgTypeId, membershipTypeId }: SearchOrganizationsParams) => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/${userId}/search`
	const params = {
		q,
		campusId,
		categoryId,
		status,
		orgTypeId,
		membershipTypeId,
	}

	return await http.get(url, { params })
}

export default searchOrganizations
